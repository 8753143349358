import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import "../index.css";
import "typeface-open-sans";
import "typeface-roboto";
import Animations, { Line } from "./Animation";
import DefaultLetter from "./Letter";
import LetterRenderer from "./LetterRenderer";
import IconFooter from "./IconFooter";
import ProfileImage from "./ProfileImage";

export default ({ children }) => (
  <Animations>
    <Container>
      <VerticleCenterContainer>
        <FlexColumnContainer>
          <ProfileImage size={"large"} />
          <HomeLetterRenderer word="Steve Bullis" Letter={HomePageLetter} />

          <RotatorContainer>
            <InnerItems className="innerIam" height={25}>
              <InnerItem to="/roles/business-consultant/">
                Business Consultant
              </InnerItem>
              <InnerItem to="/roles/software-engineer/">UI Engineer</InnerItem>
              <InnerItem>Husband &amp; Father</InnerItem>
              <InnerItem to="/roles/business-owner/">Entrepreneur</InnerItem>
              <InnerItem to="/roles/software-engineer/">
                Front End Architect
              </InnerItem>
              <InnerItem to="/roles/software-engineer/">
                React Developer
              </InnerItem>
              <InnerItem to="/roles/business-consultant/">
                Wordpress Guru
              </InnerItem>
              <InnerItem to="/roles/business-consultant/">
                Business Process Automater
              </InnerItem>
              <InnerItem to="/roles/business-consultant/">
                Trainer &amp; Mentor
              </InnerItem>
              <InnerItem to="/roles/software-engineer/">
                Web Application Developer
              </InnerItem>
            </InnerItems>
          </RotatorContainer>

          <IconFooter />
          <SubMenu>
            <MenuItem to="/roles/software-engineer/">
              Front End Architect
            </MenuItem>
            <MenuItem to="/roles/business-owner/">Entrepreneur</MenuItem>
            <MenuItem to="/roles/business-consultant/">
              Business Consultant
            </MenuItem>
          </SubMenu>
          <SubMenu>
          <MenuItem to="/stats">
              <small>Currently Programming in...</small>
            </MenuItem>
          </SubMenu>
          {children}
        </FlexColumnContainer>
      </VerticleCenterContainer>
    </Container>
  </Animations>
);

const SubMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
`;
const MenuItem = styled(Link)`
  opacity: 0;
  animation: fadein;
  animation-duration: 3s;
  animation-delay: 4s;
  text-decoration: none;
  font-size: 24px;
  margin: 10px;
  color: #aaa;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &:hover {
    color: var(--primary-color);
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
const Container = styled.div``;

const InnerItems = styled.div`
  font-size: 25px;
  line-height: 25px;
  @media screen and (min-width: 1120px){
    font-size: 50px;
    line-height: 50px;
  }
  color: var(--primary-color);
  float: left;
  overflow: hidden;
  top: 0;
  left: 0;
  display: inline-block;
  color: var(--primary-color);
  position: relative;
  white-space: nowrap;
  top: 0;
  left: 0;

  /*animation*/
  animation: moveSmall ${props => `${props.children.length * 1.5}s`};
  @media screen and (min-width: 1120px){
  animation: moveDesktop ${props => `${props.children.length * 1.5}s`};
  }
  /*animation-iteration-count*/

  animation-iteration-count: infinite;
  /*animation-delay*/

  animation-delay: 1s;

  @keyframes moveSmall {
    ${({ children, height }) => {
      const numberOfChildren = children.length;

      let keyFrames = [];

      children.map((child, index) => {
        keyFrames.push(
          `${Math.ceil((index / numberOfChildren) * 100)}% {top: ${index *
            25 *
            -1}px;}`
        );
      });
      
      return keyFrames.join("\r\n");
    }}
  }
  @keyframes moveDesktop {
    ${({ children, height }) => {
      const numberOfChildren = children.length;

      let keyFrames = [];

      children.map((child, index) => {
        keyFrames.push(
          `${Math.ceil((index / numberOfChildren) * 100)}% {top: ${index *
            50 *
            -1}px;}`
        );
      });
      
      return keyFrames.join("\r\n");
    }}
  }
`;

const InnerItem = styled(Link)`
  height: 25px;
  @media screen and (min-width: 1120px){
    height: 50px;
  }
  display: block;
  
  text-decoration: none;
  color: var(--primary-color);
`;
const RotatorContainer = styled.div`
      height: 25px;
  @media screen and (min-width: 1120px){
    height: 50px;
  }
  overflow: hidden;
`;

const HomePageLetter = styled(DefaultLetter)`
min-width: 5px;
@media screen and (min-width: 1120px){
min-width: 35px;
}
`;
const HomeLetterRenderer = styled(LetterRenderer)`
font-size: 25px;
@media screen and (min-width: 1120px){
  font-size: 50px;
}
  
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const VerticleCenterContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
