import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';

const ProfileImageContainer = styled.div`
  text-align: center;
`;

const StyledImage = styled.img`
  border-radius: ${props => `${props.size || 150}px`};
  border: 6px solid var(--primary-color);
  width: ${props => `${props.size || 150}px`};
  height: ${props => `${props.size || 150}px`};
`;


const ProfileImage = ({className})=>{
    return <ProfileImageContainer className={className}>
    <Link to="/">
      <StyledImage src="/images/meinplaid.jpg" alt="Profile Photo" />
    </Link>
  </ProfileImageContainer>

}

export default ProfileImage;