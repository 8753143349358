import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import "../index.css";
import { DefaultProfile } from "./DefaultProfile";
import Section from "./Section";
import Hobbies from "./HobbyIcon";
import "typeface-open-sans";
import "typeface-roboto";
import Animations, { Line } from "./Animation";
import LetterRenderer from "../components/LetterRenderer";
import Copyright from './Copyright';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const InnerA = styled.a`
  @media screen and (max-width: 1200px){
    justify-content: center;
  }
  margin: 5px;
  display: block;
  text-decoration: none;
  color: var(--primary-color);
  font-family: Roboto;
  font-size: 22px;
`;

const InnerItem = styled(Link)`
  margin: 5px;
  display: block;
  text-decoration: none;
  color: var(--primary-color);
  font-family: Roboto;
  font-size: 22px;
`;
const RotatorContainer = styled.div`
  height: ${props => props.height + "px"};
  overflow: hidden;
`;

const ProfileImageContainer = styled.div`
  text-align: center;
`;

const ProfileImage = styled.img`
  border-radius: 125px;
  border: 6px solid var(--primary-color);
  width: 125px;
  height: 125px;
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 15px;
  position: sticky;
  top: 0;
`;

const VerticleCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: flex-start;
  flex-grow: 1;
`;
const Spacer = styled.div`
  @media screen and (min-width: 1120px){
    height: 25vh; 
  }
`;

const IconContainer = styled.div`
  margin: 20px;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  padding: 0.5em;
`;

const StyledMain = styled.main`
  margin: 15px;
  flex-grow: 2;
`;

const Limit800 = styled.div`
max-width: 800px;
`
const LeftNav = styled.div`
  color: black;
  font-size: 25px;
  align-items: center;
  min-width: 300px;
  text-align: left;
  @media screen and (max-width: 1120px){
    text-align: center;
    
  }
`;

export default ({ title, subtitle, children }) => (
  <Animations>
    <Container>
      <VerticleCenterContainer>
        <Spacer />
        <FlexColumnContainer>
          <ProfileImageContainer>
            <Link to="/">
              <ProfileImage src="/images/meinplaid.jpg" alt="Profile Photo" />
            </Link>
          </ProfileImageContainer>

          <LetterRenderer word="Steve Bullis" />

          <LeftNav>
            <InnerItem to="/">Home</InnerItem>
            <InnerItem to="/roles/software-engineer">
              Software Engineer
            </InnerItem>
            <InnerItem to="/roles/business-owner">Entrepreneur</InnerItem>
            <InnerItem to="/roles/business-consultant">
              Business Consultant
            </InnerItem>
            <InnerItem to="/stats">Stats</InnerItem>
            <InnerA href="https://resume.stevebullis.com">Résumé</InnerA>
          </LeftNav>

          <IconContainer>
            <a href="https://www.linkedin.com/in/stephen-bullis/">
              <Icon
                alt="Linked In Logo"
                src="https://icon.now.sh/linkedin/aaa"
              />
            </a>
            <a href="tel:+15207626258">
              <Icon src="https://icon.now.sh/phone/aaa" />
            </a>
            <a href="mailto:steve@stevebullis.com">
              <Icon src="https://icon.now.sh/email/aaa" />
            </a>
          </IconContainer>
        </FlexColumnContainer>
      </VerticleCenterContainer>

      <StyledMain>
        <Limit800>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
        <div>{children}</div>
        <Copyright />
        </Limit800>
      </StyledMain>
      
    </Container>
  </Animations>
);
