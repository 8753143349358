import React from 'react'
import styled from 'styled-components'
const year = new Date().getYear() + 1900;

const Copyright = styled.footer`
  padding: 30px 0;
  color: #aaa;


`

const Copy = ({className})=> <Copyright className={className}>Copyright &copy; 2017 - {year} Steve Bullis &bull; All Right Reserved</Copyright> 

export default Copy