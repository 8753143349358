import React from "react";
import styled from "styled-components";
import Section from "./Section";

const HobbyIcon = styled.img`
  max-width: 0.5in;
  max-height: 0.5in;
`;

const HobbyGrid = styled.div`
  display: grid;
  grid-template-columns: 0.70in 0.70in 0.70in;
  column-gap: 0.05in;
  justify-content: center;
  text-align: center;
  margin: 20px 0;
`;

const Hobbies = ({ children }) => (
  <Section title="Hobbies">
    <HobbyGrid>
      <div>
        <HobbyIcon src="/images/boardgames.png" />
      </div>
      <div>
        <HobbyIcon src="/images/raspberrypi.png" />
      </div>
      <div>
        <HobbyIcon src="/images/entrepreneur.png" />
      </div>
      <div>Board Games</div>
      <div>Raspberry Pi</div>
      <div>Business Builder</div>
    </HobbyGrid>
  </Section>
);

export default Hobbies;
