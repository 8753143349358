import React, { useState, useEffect } from "react";
import styled from "styled-components";

import file_type_js from "../../static/images/file_types/file_type_js.svg";
import file_type_reactjs from "../../static/images/file_types/file_type_reactjs.svg";
import file_type_json from "../../static/images/file_types/file_type_json.svg";
import file_type_markdown from "../../static/images/file_types/file_type_markdown.svg";
import file_type_css from "../../static/images/file_types/file_type_css.svg";
import file_type_yaml from "../../static/images/file_types/file_type_yaml.svg";
import file_type_html from "../../static/images/file_types/file_type_html.svg";
import file_type_config from "../../static/images/file_types/file_type_config.svg";
import file_type_graphql from "../../static/images/file_types/file_type_graphql.svg";
import file_type_text from "../../static/images/file_types/file_type_text.svg";
import file_type_nginx from "../../static/images/file_types/file_type_nginx.svg";
import file_type_docker from "../../static/images/file_types/file_type_docker.svg";
import file_type_java from "../../static/images/file_types/file_type_java.svg";
import file_type_git from "../../static/images/file_types/file_type_git.svg";
import file_type_xml from "../../static/images/file_types/file_type_xml.svg";
import file_type_shell from "../../static/images/file_types/file_type_shell.svg";
import file_type_typescript from "../../static/images/file_types/file_type_typescript.svg";
import file_type_php from "../../static/images/file_types/file_type_php.svg";
import file_type_scss from "../../static/images/file_types/file_type_scss.svg";
import file_type_cf from "../../static/images/file_types/file_type_cf.svg";
import file_type_csharp from "../../static/images/file_types/file_type_csharp.svg";
import file_type_image from "../../static/images/file_types/file_type_image.svg";
import default_file from "../../static/images/file_types/default_file.svg";

import jsonp from "jsonp";

const languageStatsUrl =
  "https://wakatime.com/share/@04780b04-dbbc-4c44-9975-27f4880d4e13/c4d16590-a160-4a14-975f-0cbb480510fc.json";

const timeStatsUrl =
  "https://wakatime.com/share/@04780b04-dbbc-4c44-9975-27f4880d4e13/669a3823-4257-40d2-8cc0-2c6eebce0bec.json";

const Stats = () => {
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    jsonp(languageStatsUrl, null, (err, data) => {
      if (err) {
        console.error(err.message);
      } else {
        

        const otherPercent = data.data.find(item => item.name === "Other");
        const total = 100 - otherPercent.percent;

        
        setLanguages(
          data.data
            .filter(item => item.name !== "Other")
            .map(item => {
              return { ...item, value: item.percent / total };
            })
        );
      }
    });
  }, []);

  return (
    <div>
      <Flex>
        {languages.map(item => (
          <UsageItem
            key={item.name}
            name={item.name}
            value={Math.ceil(item.value * 100)}
          />
        ))}
      </Flex>
    </div>
  );
};

export default Stats;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  `

const fileByName = {
  JavaScript: file_type_js,
  JSX: file_type_reactjs,
  JSON: file_type_json,
  Markdown: file_type_markdown,
  CSS: file_type_css,
  YAML: file_type_yaml,
  HTML: file_type_html,
  Properties: file_type_config,
  GraphQL: file_type_graphql,
  Text: file_type_text,
  "Nginx configuration file": file_type_nginx,
  Docker: file_type_docker,
  Java: file_type_java,
  "Git Config": file_type_git,
  XML: file_type_xml,
  Bash: file_type_shell,
  TypeScript: file_type_typescript,
  PHP: file_type_php,
  SCSS: file_type_scss,
  Git: file_type_git,
  ColdFusion: file_type_cf,
  "C#": file_type_csharp,
  "Image (png)": file_type_image,
  PowerShell: file_type_shell,
  XAML: default_file,
  Batchfile: file_type_shell,

  default: default_file
};

const Container = styled.div`
  min-width: 100px;
  margin: 10px;
`;
const Image = styled.img`
  width: 100px;
`;
const LabelContainer = styled.div`
  text-align: center;
`;
const Header = styled.div`
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const Value = styled.div``;
const UsageItem = ({ name, value }) => {
  return (
    <Container>
      <Image        src={fileByName[          name        ] || fileByName.default}      />
      <LabelContainer>
        <Header>{name}</Header>
        <Value>{value > 0 ? value : "< 1"}%</Value>
      </LabelContainer>
    </Container>
  );
};
