import React from "react";
import styled from "styled-components";
const SectionTitle = styled.h2`
  font-family: "Roboto";
  font-size: 12pt;
  color: var(--primary-color);
  text-transform: uppercase;
  margin: 6pt 0;
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
  border-image: -webkit-gradient(linear, left bottom, right bottom, from(var(--primary-color)), to(var(--background-color)), color-stop(0.75, var(--background-color))) 31 30 30 31 repeat repeat;
  border-image-repeat: stretch;
`;
const SectionContent = styled.main`
  font-family: "Open Sans";
  font-size: 9pt;
`;

const SectionContainer = styled.section`
margin: 12px 0;
@media screen and (max-width: 7.9in) {
  &{
    margin: 15px 0;
  }
}
`

const Section = ({ title, children }) => (<SectionContainer>
    <SectionTitle>{title}</SectionTitle>
    <SectionContent>{children}</SectionContent>
</SectionContainer>);

export default Section