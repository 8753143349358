import React from 'react';
import styled from 'styled-components';

const Letter = styled.span`
  opacity: 0.25;
  &:hover {
    opacity: 1;
    animation: letterspin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  display: inline-block;
  min-width: 15px;
  text-align: center;
  margin: 1px;

  animation: letterspinintro;
  animation-delay: ${props => `${0.25 * props.index + 1}s`};
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @keyframes letterspin {
    0% {
      opacity: 1;
      transform: scale(1);
      color: var(--primary-color);
    }
    5% {
      opacity: 1;
      transform: scale(1.5);
      color: var(--primary-color);
    }
    10% {
      opacity: 1;
      transform: scale(2);
      color: var(--primary-color);
    }
    15% {
      opacity: 1;
      transform: scale(1.5);
      color: var(--primary-color);
    }
    20% {
      opacity: 1;
      transform: scale(1);
      color: var(--primary-color);
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(30deg);
    }
    35% {
      transform: rotate(60deg);
    }
    45% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(120deg);
    }
    60% {
      transform: rotate(150deg);
    }
    70% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg) scale(1);
      color: var(--primary-color);
    }
    100% {
      opacity: 1;
      transform: rotate(0deg);
      color: black;
    }
  }
  @keyframes letterspinintro {
    0% {
      opacity: 1;
      transform: scale(10);
      color: var(--primary-color);
    }
    5% {
      opacity: 1;
      transform: scale(4);
      color: var(--primary-color);
    }
    10% {
      opacity: 1;
      transform: scale(3);
      color: var(--primary-color);
    }
    15% {
      opacity: 1;
      transform: scale(2);
      color: var(--primary-color);
    }
    20% {
      opacity: 1;
      transform: scale(1);
      color: var(--primary-color);
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(30deg);
    }
    35% {
      transform: rotate(60deg);
    }
    45% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(120deg);
    }
    60% {
      transform: rotate(150deg);
    }
    70% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg) scale(1);
      color: var(--primary-color);
    }
    100% {
      opacity: 1;
      transform: rotate(0deg);
      color: black;
    }
  }
`;

export default Letter;